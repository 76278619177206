import { useState, useEffect } from "react";

const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // loading state of fetched data
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(url)
      .then(res => {
        console.log(res);
        if (!res.ok) {
          console.log('res fail');
          throw Error('Failed to retrieve data');
        }
        return res.json()
      })
      .then((data) => {
        console.log(data);
        setData(data);
        setIsLoading(false);
        setError(null);
      })
      .catch(err => {
        setIsLoading(false);
        setError(err.message);
        // console.log(err.message);
      })
  }, [url]);

  return {data, isLoading, error}
}

export default useFetch;