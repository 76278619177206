import './Header.css';
import React from 'react'
import {Container, Navbar, Nav} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {useParams, useLocation} from 'react-router-dom';

function Header() {
  const location = useLocation();
  console.log('Header location', location);
  const {accid,repid} = useParams();
  console.log('Header accid', accid);
  console.log('Header repid', repid);
  return (
    <Navbar bg="dark" variant="dark" expand="md">
       <Container fluid>
        <LinkContainer to="/">
          <Navbar.Brand>Crown Jewel Brand</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="navbar-center">
            <LinkContainer to="/">
              <Nav.Link>HOME</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/acc/${accid}/rep/${repid}/rep-accounts`}>
              <Nav.Link>ACCOUNTS</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/">
              <Nav.Link>ORDERS</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/">
              <Nav.Link>LINE SHEETS</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/">
              <Nav.Link>LINE STOCK</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
       </Container>
    </Navbar>
  );
}

export default Header;
