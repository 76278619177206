import React from 'react'
import Home from './pages/Home';
import RepAccounts from "./pages/RepAccounts";
import NotFound from "./pages/NotFound";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route path="/acc/:accid/rep/:repid" element={<Home rep={true} />} />
        <Route path="/acc/:accid/rep/:repid" element={<Home account={true} />} />
        <Route path="/acc/:accid/rep/:repid/rep-accounts" element={<RepAccounts/>} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </Router>
  );
}

export default App;
