import Accounts from '../components/Accounts';
import useFetch from '../hooks/useFetch';
import UserContext from '../components/UserContext';
import Header from '../components/Header';

const RepAccounts = () => {
  console.log('UserContext in RepAccounts', UserContext());
  const context = UserContext();
  const {data: accounts, isLoading, error} = useFetch(`https://api.crownjewelbrand.com/rep/accounts/${context.repid}`);
  return (
    <>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>Rep {context.repid}</h1>
            {error && <div className='text-danger'>{error}</div>}
            {isLoading && <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div>}
            {accounts && <Accounts accounts={accounts} />}
          </div>
        </div>
      </div>
    </>
  );
}

export default RepAccounts;