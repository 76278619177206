import React from 'react'

const Accounts = ({accounts}) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Account</th>
          <th>First</th>
          <th>Last</th>
          <th>Handle</th>
        </tr>
      </thead>
      <tbody>
        {accounts.map((account) => (
        <tr key={account.rep_id}>
          <td>{account.company_name}</td>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Accounts;