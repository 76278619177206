import React from 'react'
import {useParams, useSearchParams, Navigate} from 'react-router-dom';

function NotFound(type) {
  const {accid,repid} = useParams();
  const [searchParams] = useSearchParams();
  const activity = searchParams.get('activity');
  const affiliate_enc = searchParams.get('affiliate_enc');
  const rep = searchParams.get('rep');

  console.log('activity search param:', activity);
  console.log('affiliate_enc:', affiliate_enc);
  console.log('rep search param:', rep);
  
  console.log('accid', accid);
  console.log('repid', repid);
  let {accountRedirect, repRedirect, repAccountRedirect} = false;
  if (rep.length > 5 && affiliate_enc.length > 5) {
    repAccountRedirect = true;
  } else if (rep.length > 5) {
    repRedirect = true;
  } else if (affiliate_enc.length > 5) {
    accountRedirect = true;
  }
  return (
    <>
    {repAccountRedirect && <Navigate replace to={`/rep/${rep}/acc/${affiliate_enc}/${activity}`} />}
    {repRedirect && <Navigate replace to={`/rep/${rep}/${activity}`} />}
    {accountRedirect && <Navigate replace to={`/acc/${affiliate_enc}/${activity}`} />}
    </>
  );
}

export default NotFound;