import React from 'react';
import {useParams} from 'react-router-dom';
import Header from '../components/Header';

function Home(type) {
  const {accid,repid} = useParams();
  console.log('accid', accid);
  console.log('repid', repid);
  console.log('type', type);
  return (
    <>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col">
          <h1>Home</h1> 
          {type.rep && <div>Rep</div>}
          {type.account && <div>Account</div>}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;